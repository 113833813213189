import React, { useState, useEffect } from "react";
import "../styles/Details.css";
import { Card, Modal, message, Tooltip } from "antd";
import { getConfigInfo } from "../services/PowerBIService";
import { CopyOutlined } from "@ant-design/icons";

function Analytics() {
    document.title = "LunaJoy Dashboard";
    const [url, setUrl] = useState("")
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")

    useEffect(() => {
        console.log("Call api powerbi")
        loadData()
    }, [])

    const loadData = async() =>{
        try{
            const config = await getConfigInfo();
            console.log(config)
            if(config?.length){
                setUrl(config[0]?.url)
                setUserName(config[0]?.username)
                setPassword(config[0]?.password)
            }
        }catch(e){
            console.log("failed to load data from database", e)
            Modal.confirm({
              title: 'Error Message',
              content: 'Oops! Error in Fetching data',
              okText: 'Ok',
              cancelButtonProps: { style: { display: 'none' } },
              onOk: async () => {
                console.log("Error in Fetching data");
              },
            });
        }

    }

    const copyUsername = () => {
        navigator.clipboard.writeText(userName)
          .then(() => message.success('Username copied to clipboard'))
          .catch(() => message.error('Failed to copy username'));
      }



    const copyPassword = () => {
        navigator.clipboard.writeText(password)
          .then(() => message.success('Password copied to clipboard'))
          .catch(() => message.error('Failed to copy password'));
      }

    return (
        <div id="dashboard-container" className="report-container"  >
            <Card style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
                <p style={{fontSize: '20px'}}><strong>Please visit following URL to view the performance dashboard : </strong></p>
                <p style={{fontSize: '20px', marginBottom: '40px'}}><a href={url} target="_blank">{url}</a></p>
                <p style={{fontSize: '20px'}}><strong>Kindly use following credential to login after visiting the link:</strong> </p>
                <p style={{fontSize: '20px', marginBottom: '0px'}}><strong>Username:</strong> {userName} <Tooltip title={"Copy Username"}><CopyOutlined onClick={copyUsername}/></Tooltip></p>
                <p style={{fontSize: '20px'}}><strong>Password:</strong> {password} <Tooltip title={"Copy Password"}><CopyOutlined onClick={copyPassword}/></Tooltip></p>
            </Card>
        </div>
    );
}

export default Analytics;