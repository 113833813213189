import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Spin, Upload } from 'antd';
import React, { useState } from 'react';
import { notify } from '../utils/notify';
import ParticipationMatrixService from '../services/ParticipationMatrixService';

const UploadCredentialingSummaryForm = ({ setUploadModalOpen, setModalTitle, setModalComponent }) => {
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [form] = Form.useForm();

    const formItemLayout = {
        labelCol: { span: 7 },
        wrapperCol: { span: 16 }
    };

    const buttonStyle = {
        background: "#faad14",
        color: "black",
        float: "none",
        margin: 0,
        alignSelf: "center",
    };

    const handleCancel = () => {
        setUploadModalOpen(false);
    };

    const handleFileUpload = async ({ file, onSuccess, onError }) => {
        if (file) {
            onSuccess('ok');
            setFileList([file]);
        } else {
            onError('.');
            setFileList([]);
        }
    };

    const onFinish = async () => {
        setLoading(true);

        setModalTitle('')
        setModalComponent(
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '1rem',
                    marginTop: '1rem'
                }}
            >
                <Spin />
                <p>Uploading Credentialing Summary...</p>
            </div>
        )

        const formData = new FormData();

        if (fileList.length > 0) {
            formData.append('file', fileList[0]);
        } else {
            Modal.confirm({
                closable: false,
                title: 'Error',
                content: (
                    <div>
                        <p>Please select the file.</p>
                    </div>
                ),
                okText: 'Ok',
                cancelButtonProps: { style: { display: 'none' } }
            });
            setLoading(false);
            return;
        }

        try {
            const data = await ParticipationMatrixService.uploadCredentialingSummary(formData)

            if (data.result.notFoundInsurances.length) {
                Modal.confirm({
                    closable: false,
                    title: '',
                    content: (
                        <div>
                            <p>The data for Following insurances are not added because the insurances are missing.</p>
                            {data.result.notFoundInsurances.map((ins) => (<span key={ins}>{`${ins}, `}</span>))}
                            <p style={{ marginTop: '1rem' }}>Please contact the dev team.</p>
                        </div>
                    ),
                    okText: 'Ok',
                    cancelButtonProps: { style: { display: 'none' } }
                });
            } else {
                notify(`Uploaded Credentialing Summary Successfully!`);
            }

        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An unexpected error occurred. Please try again.';

            Modal.error({
                title: 'Error',
                content: (
                    <div>
                        <p>{errorMessage}</p>
                    </div>
                ),
                okText: 'Ok',
                cancelButtonProps: { style: { display: 'none' } }
            });
        } finally {
            setLoading(false);
            setUploadModalOpen(false);
            setFileList([]);
        }
    };

    return (
        <Spin spinning={loading}>
            <Form form={form} onFinish={onFinish} {...formItemLayout} encType="multipart/form-data" style={{ marginTop: '2rem' }}>
                <Form.Item
                    label="File"
                    name="file"
                    rules={[{ required: true, message: 'Please upload a file!' }]}
                >
                    <Upload
                        maxCount={1}
                        fileList={fileList}
                        onRemove={() => setFileList([])}
                        customRequest={handleFileUpload}
                        accept=".xlsx,.xls" // Only accept Excel files
                    >
                        <Button icon={<UploadOutlined />}>Upload Credentialing Summary</Button>
                        <div>
                            <small>*(File type must be .xlsx or .xls)</small>
                        </div>
                    </Upload>
                </Form.Item>

                <div style={{ display: "flex", justifyContent: "end", marginBottom: '-24px' }}>
                    <Form.Item style={{ marginRight: 16 }}>
                        <Button onClick={handleCancel}>Cancel</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={buttonStyle}>
                            Submit
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Spin>
    );
};

export default UploadCredentialingSummaryForm;
