import { z } from 'zod'

// custom email verification
const isValidEmail = (value) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(value)
}

function phone() {
  return [
    (value) => /^\d{10}$/.test(value),
    { message: 'Please enter a valid phone number.' }
  ]
}


export const schema = {
  email: z.string({
    required_error: 'Please enter the email address.'
  }).refine((value) => isValidEmail(value), {
    message: 'Please enter a valid email address.'
  }),
  firstName: z.string({
    required_error: 'Please enter the first name.'
  }).min(2).refine((value) => /^[a-zA-Z]+[-'s]?[a-zA-Z]+$/.test(value), 'Please enter a valid first name.'),
  lastName: z.string({
    required_error: 'Please enter the last name.'
  }).min(2).refine((value) => /^[a-zA-Z]+[-'s]?[a-zA-Z]+$/.test(value), 'Please enter a valid last name.'),
  mobileNumber: z.string({
    required_error: 'Please enter the mobile number.'
  }).refine(...phone()),
  dob: z.string({
    required_error: 'Please enter the date of birth.'
  }).refine((value) => {
    // Check if the value matches the MM/DD/YYYY format
    if (!/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(value)) {
      return false
    }
    // Parse the date from the value
    const parts = value.split('/')
    const year = parseInt(parts[2], 10)
    const month = parseInt(parts[0], 10) - 1 // Months are 0-based
    const day = parseInt(parts[1], 10)

    // Create a Date object with the parsed values
    const selectedDate = new Date(year, month, day)

    // Get today's date
    const today = new Date()

    // Create a Date object for January 1, 1900
    const minDate = new Date(1900, 0, 1)

    // Check if the selected date is not before January 1, 1970 and not after today
    return selectedDate >= minDate && selectedDate <= today
  }, {
    message: 'Please enter a valid DOB.'
  })
}