import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Select, DatePicker, Checkbox, Spin, Modal, Switch, Upload } from 'antd'
import { notify } from '../utils/notify'
import { getStates } from '../services/StateService'
import { UploadOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { validateForm } from '../utils/validateForms'
import { insertClinic } from '../services/ClinicsService'
import { getAllGHLTagMaster } from '../services/GHLTagMasterService'
import { updateStateOfTagMaster } from '../utils/GHLTagMasterUpdateState'

const { Option } = Select

const typesOfScreen = [
  { value: 'Obgyn', text: 'EPDS' },
  { value: 'PHQ9-GAD7', text: 'PHQ9-GAD7' },
  { value: 'Geriatric', text: 'GDS' }
]

const faxSequence = ['C-Tab Screens', 'C-Positive Screens', 'C-Referral']

const ehrSystem = ['None', 'AthenaHealth', 'eCW', 'eCW - Femwell', 'Epic', 'Cerner', 'AllScripts']
const typeOfIntegration = [
  { value: 'Sync Data', text: 'Yes' },
  { value: 'None', text: 'No' }
]

const CarePartnerForm = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [ehrName, setEhrName] = useState('')
  const [states, setStates] = useState([])
  const [sourceTechnologyType, setSourceTechnologyType] = useState([])
  const [sourceTechnology, setSourceTechnology] = useState([])
  const [sourceOrganizationType, setSourceOrganizationType] = useState([])
  const [sourceOrganizationName, setSourceOrganizationName] = useState([])
  const [specialty, setSpecialty] = useState([])
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    console.log(form)
  }, [form])

  useEffect(() => {
    setLoading(true)
    loadData()
  }, [])

  const loadData = async () => {
    const state = await getStates()
    setStates([{ code: '--', title: 'None' }, ...state])
    const ghlTagMasters = await getAllGHLTagMaster()
    updateStateOfTagMaster(
      ghlTagMasters,
      setSourceTechnologyType,
      setSourceTechnology,
      setSourceOrganizationType,
      setSourceOrganizationName,
      setSpecialty
    )
    setLoading(false)
  }

  const onFinish = async (data) => {
    setLoading(true)
    const errors = validateForm(data)
    if (Object.keys(errors).length === 0) {
      // Retrieve the user object from local storage
      const user = JSON.parse(localStorage.getItem('user'))

      const formData = new FormData()
      formData.append('name', data.name)
      formData.append('address', data.address)
      formData.append('currentUser', user.name)
      fileList?.length && data?.logo?.fileList.length > 0 && formData.append('logo', data.logo.fileList[0].originFileObj)
      data.faxNumber && formData.append('faxNumber', data.faxNumber)
      data.faxSequence && formData.append('faxSequence', data.faxSequence)
      data.isFaxScheduled && formData.append('isFaxScheduled', data.isFaxScheduled)
      data.clinicans && formData.append('clinicans', data.clinicans)
      data.typesOfScreen && formData.append('typesOfScreen', data.typesOfScreen)
      data.ehr && formData.append('ehr', data.ehr)
      data.ehrId && formData.append('ehrId', data.ehrId)
      formData.append('typesOfIntegration', data.typesOfIntegration ? 'Sync Data' : 'None')
      data.referralLink && formData.append('referralLink', data.referralLink)
      data.allowReferral && formData.append('allowReferral', data.allowReferral)
      data.alias && formData.append('alias', data.alias)
      data.streetName && formData.append('streetName', data.streetName)
      data.sourceTechnology && formData.append('sourceTechnology', data.sourceTechnology)
      data.sourceTechnologyType && formData.append('sourceTechnologyType', data.sourceTechnologyType)
      data.sourceOrganizationType && formData.append('sourceOrganizationType', data.sourceOrganizationType)
      data.sourceOrganizationName && formData.append('sourceOrganizationName', data.sourceOrganizationName)
      data.specialty && formData.append('specialty', data.specialty)
      try {
        await insertClinic(formData)
        notify(`"${data.name}" Clinic Added Successfully!`)
        navigate('/clinic')
      } catch (error) {
        console.log(error)
        Modal.confirm({
          title: 'Error',
          content: (
            <div>
              <p>Oops! Failed to add care partner details.</p>
              <p>{error.message}</p>
              {error.message === 'Validation error' ? (
                <>
                  <div>* Clinic name already exist in database.</div>
                </>
              ) : null}
            </div>
          ),
          okText: 'Ok',
          cancelButtonProps: { style: { display: 'none' } }
        })
      }
      setLoading(false)
    } else {
      setLoading(false)
      console.log(errors)
      const errorMessages = Object.values(errors).map((error, index) => <div key={index}>* {error}</div>)
      Modal.confirm({
        title: 'Error Message',
        content: (
          <div>
            <p>Oops! Cannot add data due to the following errors:</p>
            {errorMessages}
          </div>
        ),
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } }
      })
    }

    return true
  }

  const goBack = () => {
    navigate('/clinic')
  }

  const formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 16 }
  }

  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img')
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      img.onload = () => {
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0, img.width, img.height)

        const width = canvas.width
        const height = canvas.height

        resolve({ width, height })
      }

      img.onerror = () => {
        reject('Error loading image')
      }

      img.src = URL.createObjectURL(file)
    })
  }

  const handleFileUpload = async ({ file, onSuccess, onError }) => {
    if (file.size > 90 * 1024) {
      onError('File size should be less than 90kb')
      setFileList([])
      Modal.confirm({
        title: 'Error',
        content: (
          <div>
            <p>File size should be less than 90kb</p>
          </div>
        ),
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } }
      })
    } else {
      const dimensions = await getImageDimensions(file)
      if (dimensions.width <= 750 && dimensions.height <= 500) {
        onSuccess('ok')
        setFileList([file])
      } else {
        onError('This image does not meet the required resolution.')
        setFileList([])
        Modal.confirm({
          closable: false,
          title: 'Error',
          content: (
            <div>
              <p>This image does not meet the required resolution.</p>
            </div>
          ),
          okText: 'Ok',
          cancelButtonProps: { style: { display: 'none' } }
        })
      }
    }
  }

  return (
    <Spin spinning={loading}>
      <Form form={form} onFinish={onFinish} {...formItemLayout} encType="multipart/form-data">
        {/* Name */}
        <Form.Item
          name="name"
          label="Clinic name"
          rules={[
            {
              required: true,
              message: 'Please enter the care partner name.'
            }
          ]}
        >
          <Input style={{ width: 300 }} />
        </Form.Item>

        {/* Address */}
        <Form.Item
          name="address"
          label="State"
          rules={[
            {
              required: true,
              message: 'Please select a state.'
            }
          ]}
        >
          <Select
            placeholder="Select a state"
            style={{ width: 300 }}
            mode="multiple"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {states.map((state) => (
              <Option key={state.code} value={state.code}>
                {state.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="streetName" label="Street name">
          <Input placeholder="Enter street name" style={{ width: 300 }} />
        </Form.Item>

        {/* Assessment Type */}
        <Form.Item name="typesOfScreen" label="Type of screener">
          <Select placeholder="Select a screener type" mode="multiple" style={{ width: 300 }}>
            {typesOfScreen.map((screener) => (
              <Option key={screener.value} value={screener.value}>
                {screener.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="referralLink" label="Referral Link">
          <Input placeholder="Enter referral link" style={{ width: 300 }} />
        </Form.Item>
        <Form.Item label="Logo" name="logo">
          <Upload
            maxCount={1}
            fileList={fileList}
            onRemove={(value) => {
              setFileList([])
            }}
            customRequest={handleFileUpload}
            listType="text"
            accept=".jpg,.jpeg,.png"
          >
            <Button icon={<UploadOutlined />}>Upload Logo</Button>
            <div>
              <small>*(Size of image should be less that 90kb)</small>
              <br />
              <small>*(Logo dimensions must not exceed 750x500 pixels.)</small>
            </div>
          </Upload>
        </Form.Item>

        {/* Fax Number */}
        <Form.Item name="faxNumber" label="Fax number">
          <Input addonBefore="+1" style={{ width: 300 }} />
        </Form.Item>

        {/* Is Fax Scheduled */}
        <Form.Item name="isFaxScheduled" label="Schedule fax">
          <Switch style={{ width: 20 }} />
        </Form.Item>

        {/* Fax Sequence */}
        <Form.Item name="faxSequence" label="Fax sequence">
          <Select placeholder="Select a fax sequence" mode="multiple" style={{ width: 300 }}>
            {faxSequence.map((fax) => (
              <Option key={fax} value={fax}>
                {fax}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="clinicans" label="Clinicians">
          <Select mode="tags" placeholder="Add clinicians" style={{ width: 300 }} tokenSeparators={[',']} />
        </Form.Item>

        <Form.Item name="sourceTechnologyType" label="Source technology type">
          <Select
            placeholder="Select a source technology type"
            style={{ width: 300 }}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {sourceTechnologyType.map((element) => (
              <Option key={element} value={element}>
                {element}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="sourceTechnology" label="Source technology">
          <Select
            placeholder="Select a source technology"
            style={{ width: 300 }}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {sourceTechnology.map((element) => (
              <Option key={element} value={element}>
                {element}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="sourceOrganizationType" label="Source organization type">
          <Select
            placeholder="Select a source organization type"
            style={{ width: 300 }}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {sourceOrganizationType.map((element) => (
              <Option key={element} value={element}>
                {element}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="sourceOrganizationName" label="Source organization name">
          <Select
            placeholder="Select a source organization name"
            style={{ width: 300 }}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {sourceOrganizationName.map((element) => (
              <Option key={element} value={element}>
                {element}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="specialty" label="Specialty">
          <Select
            placeholder="Select a specialty"
            style={{ width: 300 }}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {specialty.map((element) => (
              <Option key={element} value={element}>
                {element}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* EHRId */}
        <Form.Item name="ehr" label="EHR name">
          <Select
            placeholder="Select a EHR"
            style={{ width: 300 }}
            onChange={(value) => {
              setEhrName(value)
            }}
          >
            {ehrSystem.map((element) => (
              <Option key={element} value={element}>
                {element}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {ehrName && ehrName !== 'None' ? (
          <Form.Item name="alias" label="Clinic Name in EHR">
            <Input style={{ width: 300 }} />
            <div>
              <small>*(Provider the clinic name send by EHR)</small>
            </div>
          </Form.Item>
        ) : null}

        {ehrName === 'AthenaHealth' ? (
          <>
            <Form.Item name="ehrId" label="Practice id">
              <Input style={{ width: 300 }} />
            </Form.Item>

            <Form.Item name="typesOfIntegration" label="Enable sync">
              <Switch style={{ width: 20 }} />
            </Form.Item>

            <Form.Item label="Athena referral" name="allowReferral" valuePropName="checked">
              <Checkbox></Checkbox>
            </Form.Item>
          </>
        ) : null}
        {/* Back and Submit Buttons */}
        <div style={{ display: 'flex', marginLeft: 200 }}>
          <Form.Item style={{ marginRight: 16 }}>
            <Button onClick={goBack}>Back</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  )
}

export default CarePartnerForm
