// Assuming you have axios imported
import axios from 'axios';
import { json } from 'react-router-dom';
import { BACKEND_URL } from '../utils/urls';

export default class MonthlyFaxServices {

    static async getMonhtlyFaxReview() {
        let requestData = {
            url: `${BACKEND_URL}/api/monthlyFaxReviewReport`,
            method: "GET"
        }

        try {
            let response = await axios.request(requestData);

            if (response.status === 200 && response.data) {
                const resData = await response.data;
                
                return resData;

            } else {
                throw json({ message: 'Could not fetch events.' }, { status: 500 });
            }
        } catch {
            throw json({ message: 'Could not fetch events.' }, { status: 500 });
        }

    }
    static async getDocument(record) {
        const url = `${BACKEND_URL}/api/monthlyFaxReviewReport/${record.id}/document`;
        try {     
            const response = await axios.get(url, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            console.log("blobmonthly -- ",blob)
            return blob;
        } catch (error) {
            console.error('Error while downloading document: ', error);
        }

    }
    static async handelIsReview(id) {
        try {
        
          const response = await axios.post(`${BACKEND_URL}/api/monthlyFaxReviewReport/${id}/Review`);
          if (response.status === 200 && response.data) {
            
            console.log(`Review successful for ID ${id}`);
            
          } else {
            throw new Error('Review failed.');
          }
        } catch (error) {
          console.error(`Review failed for ID ${id}:`, error.message);
         
        }
      }
      static async handleFaxGeneratedRetry(id) {
        try {
            
          const response = await axios.post(`${BACKEND_URL}/api/monthlyFaxReviewReport/${id}/handleFaxGeneratedRetry`);
          console.log(response)
          if (response.status === 200 && response.data) {
            
            console.log(`Retry successful for ID ${id}`);
            
          } else {
            throw new Error('Retry failed.');
          }
        } catch (error) {
          console.error(`Retry failed for ID ${id}:`, error.message);
         
        }
      }
    
};

