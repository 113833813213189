import React, { useState, useEffect, useRef } from 'react'
import '../styles/Details.css'
import { DeleteOutlined, EditOutlined, FilterOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Tag, Tooltip, Card, Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import Referral from '../assets/Referrals_Icon.svg'
import FilterComponent from '../components/FilterComponent'
import { Link } from 'react-router-dom'
import { getClinics, deleteClinic } from '../services/ClinicsService'
import { formatDate } from '../utils/dateFormat'
import { promoClinic, promoClinicSlugMap } from '../assets/data/promo-clinic'
import { oldObGynClinic, oldObgynClinicSlugMap } from '../assets/data/old-obgyn-clinic'
import { existingPhq9Gad7Tags, existingObgynTags } from '../assets/data/existing-tags-'

const { Column } = Table

function ClinicsDetails() {
  document.title = 'LunaJoy Dashboard'
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalComponent, setModalComponent] = useState()
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const [filters, setFilters] = useState({})
  const [filterClinic, setFilterClinic] = useState(null)
  const [typeOfScreening, setTypeOfScreening] = useState([])
  const [typeOfEhr, setTypeOfEhr] = useState(null)
  const [loading, setLoading] = useState(false)
  const [clinicNames, setClinicNames] = useState([])
  const [otherClinicNames, setOtherClinicNames] = useState([])
  const [filterByOtherNameOfClinic, setFilterByOtherNameOfClinic] = useState(null)
  const clinicSet = new Set()
  const otherClinicSet = new Set()

  const filterCardRef = useRef(null)
  const filterButtonRef = useRef(null)

  const bookingUrl =
    window.location.hostname === 'portal.hellolunajoy.com' ? `https://booking.hellolunajoy.com` : `https://booking-dev.hellolunajoy.com`

  const handleTableChange = (pagination, filters) => {
    setFilters(filters)
  }

  const handleClickOutside = (event) => {
    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target) &&
      filterCardRef.current &&
      !filterCardRef.current.contains(event.target)
    ) {
      setIsFilterVisible(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [filterClinic, filterByOtherNameOfClinic, typeOfScreening, typeOfEhr])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleFilter = () => {
    setIsFilterVisible(true)
  }

  const handleClose = () => {
    setIsFilterVisible(false)
  }

  async function loadData(filter) {
    try {
      setLoading(true)
      await loadClinicData()
      const typesOfScreeningString = typeOfScreening.length !== 0 ? typeOfScreening.toString() : null
      const details = await getClinics(filterClinic, filterByOtherNameOfClinic, typesOfScreeningString, typeOfEhr)

      setData(
        details.map((data) => {
          let oblink = null
          if (data.screeningSlugs['obgyn']) {
            if (promoClinic.includes(data.clinicCode)) {
              oblink = `https://forms.hellolunajoy.com/ob-gyn/promo/${promoClinicSlugMap[data.clinicCode]}`
            } else if (oldObGynClinic.includes(data.clinicCode)) {
              oblink = `https://forms.hellolunajoy.com/ob-gyn/${oldObgynClinicSlugMap[data.clinicCode]}`
            } else {
              oblink = `https://forms.hellolunajoy.com/screening/${data.screeningSlugs['obgyn']}`
            }
          }
          return {
            key: data.id,
            id: data.id,
            clinicCode: data.clinicCode,
            date: data.createdAt,
            name: data.name,
            address: data.address,
            faxNumber: data.faxNumber,
            faxSequence: data.faxSequence ? data.faxSequence.toString() : data.faxSequence,
            isFaxScheduled: data.isFaxScheduled,
            ehr: data.ehr,
            ehrId: data.ehrId,
            typesOfScreen: data.typesOfScreen ? data.typesOfScreen.toString() : data.typesOfScreen,
            typesOfIntegration: data.typesOfIntegration,
            landingPageUrl: data.landingPageUrl,
            clinicians: data.clinicians ? data.clinicans.toString() : data.clinicans,
            screeningSlugs: data.screeningSlugs,
            obLink: oblink,
            alias: data.alias,
            tags: data.tags,
            referralLink: data?.referralLink ? data.referralLink : null,
            clinicGHLTags: data?.clinicGHLTags ? data.clinicGHLTags : null,
            streetName: data?.streetName ? data.streetName : null
          }
        })
      )
    } catch (e) {
      console.log(e)
      Modal.confirm({
        title: 'Error Message',
        content: 'Oops! Error in Fetching data',
        okText: 'Ok',
        onOk: async () => {
          console.log('Error in Fetching data')
        },
        cancelButtonProps: { style: { display: 'none' } }
      })
    } finally {
      setLoading(false)
    }
  }

  async function loadClinicData() {
    try {
      const details = await getClinics()
      details.map((data) => {
        clinicSet.add(data.name)
        if (data.alias?.length) {
          otherClinicSet.add(data.alias)
        }
      })
      setClinicNames([...clinicSet].sort())
      setOtherClinicNames([...otherClinicSet].sort())
    } catch (e) {
      Modal.confirm({
        title: 'Error Message',
        content: 'Oops! Error in Fetching data',
        okText: 'Ok',
        onOk: async () => {
          console.log('Error in Fetching data')
        },
        cancelButtonProps: { style: { display: 'none' } }
      })
    }
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleDeleteClinic = (id, name) => {
    Modal.confirm({
      title: 'Confirm Deletion',
      content: `Are you sure you want to delete "${name}" Clinic?`,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          // Call the frontend service to delete the clinic by id
          await deleteClinic(id)

          // If deletion is successful, refresh the data to update the table
          loadData()
        } catch (error) {
          console.error('Error deleting clinic:', error.message)
        }
        console.log('Deleting clinic with id:', id)
      }
    })
  }

  return (
    <div id="dashboard-container" className="responsive-container">
      {/* <Button className='refresh-btn' onClick={navigate('/add-clinic')}>Add Clinic</Button> */}
      <div className="row styleFilterRow">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ marginTop: 5 }}>
            <span style={{ marginLeft: '18px', fontWeight: 500, marginRight: 10, marginTop: 13 }}>Total Clinics</span>

            <Tag>
              <strong style={{ fontSize: 16 }}>{data.length === 0 ? 0 : data.length}</strong>
            </Tag>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <span className="dateStyling" style={{ marginRight: '10px' }}>
              Filter
            </span>
            <div style={{ position: 'relative' }}>
              <Tooltip title="Filter">
                <FilterOutlined
                  style={{
                    marginRight: '20px',
                    fontSize: '18px',
                    marginTop: '5px',
                    cursor: 'pointer'
                  }}
                  onClick={handleFilter}
                  ref={filterButtonRef}
                />
              </Tooltip>

              {isFilterVisible && (
                <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 100 }} ref={filterCardRef}>
                  <Card
                    title="Filter Options"
                    bordered={true}
                    extra={
                      <Button type="Close" onClick={handleClose}>
                        <CloseCircleOutlined />
                      </Button>
                    }
                  >
                    <FilterComponent
                      clinicNames={clinicNames}
                      filteredClinic={filterClinic}
                      setFilteredClinic={setFilterClinic}
                      otherClinicNames={otherClinicNames}
                      filteredOtherClinic={filterByOtherNameOfClinic}
                      setFilteredOtherClinic={setFilterByOtherNameOfClinic}
                      assessmentType={typeOfScreening}
                      setAssessmentType={setTypeOfScreening}
                      ehrType={typeOfEhr}
                      setEhrType={setTypeOfEhr}
                      from={'Clinic'}
                    />
                  </Card>
                </div>
              )}
            </div>
            <Button onClick={() => navigate('/add-clinic')}>Add Clinic</Button>
          </div>
        </div>
      </div>

      <Spin spinning={loading}>
        <Table dataSource={data} onChange={handleTableChange} bordered={true}>
          <Column
            title=" Clinic Name"
            dataIndex="name"
            key="name"
            sorter={(a, b) => a.name.localeCompare(b.name)}
            render={(name, record) => (
              <>
                <div>{name ? name : ''}</div>
                <div>Fax: {record?.faxNumber ? record.faxNumber : ''}</div>
              </>
            )}
          />
          <Column title=" Other Name" dataIndex="alias" key="alias" />
          <Column
            title="Date"
            dataIndex="date"
            key="date"
            render={(date) => (
              <>
                <div>{date ? formatDate(date) : ' '}</div>
              </>
            )}
          />

          <Column title="State" dataIndex="address" key="address" />

          <Column
            title="Type of Screening"
            dataIndex="typesOfScreen"
            key="typesOfScreen"
            width={120}
            render={(typesOfScreen, record) => (
              <>
                {typesOfScreen?.length ? (
                  <>
                    {typesOfScreen.split(',').map((tagFor) => {
                      return (
                        <>
                          <Tag color="blue" key={tagFor} style={{ margin: '2px' }}>
                            {tagFor === 'Obgyn' ? 'EPDS' : tagFor === 'Geriatric' ? 'GDS' : tagFor}
                          </Tag>
                          <br />
                        </>
                      )
                    })}
                  </>
                ) : null}
              </>
            )}
          />

          <Column
            title="Fax Sequence"
            dataIndex="faxSequence"
            key="faxSequence"
            render={(faxSequence, record) => (
              <>
                {record.isFaxScheduled ? (
                  <>
                    {faxSequence?.length ? (
                      <>
                        {faxSequence.split(',').map((element) => {
                          return (
                            <>
                              <Tag color="blue" key={element} style={{ margin: '2px' }}>
                                {element}
                              </Tag>
                              <br />
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <Tag color="blue" style={{ margin: '2px' }}>
                        Not Available
                      </Tag>
                    )}
                  </>
                ) : (
                  <Tag color="blue" style={{ margin: '2px' }}>
                    Not Available
                  </Tag>
                )}
              </>
            )}
          />

          <Column
            title="Links"
            dataIndex="landingPageUrl"
            key="landingPageUrl"
            width={150}
            minWidth={150}
            render={(landingPageUrl, record) => (
              <>
                {landingPageUrl ? (
                  <>
                    {' '}
                    <a href={landingPageUrl} target="_blank" rel="noreferrer">
                      Landing Page{' '}
                    </a>{' '}
                    <br />
                  </>
                ) : null}
                <a href={`${bookingUrl}/?referal=${record.clinicCode}`} target="_blank" rel="noreferrer">
                  Booking
                </a>
                <br />

                {/* Removed Screening links and kept only landing page link */}
                {/* {record?.typesOfScreen?.length ? (
                  <>
                    {record.typesOfScreen.split(',').map((tagFor) => {
                      if (tagFor === 'Obgyn') {
                        return (
                          <>
                            {' '}
                            <a href={record.obLink} target="_blank">
                              EPDS
                            </a>
                            <br />
                          </>
                        )
                      }
                      if (tagFor === 'PHQ9-GAD7') {
                        return (
                          <>
                            {' '}
                            <a href={`https://forms.hellolunajoy.com/screening/${record?.screeningSlugs['phq9-gad7']}`} target="_blank">
                              PHQ9-GAD7{' '}
                            </a>
                            <br />
                          </>
                        )
                      }
                      // GDS has been discontinued
                      // if (tagFor === 'Geriatric') {
                      //   return (
                      //     <>
                      //       <a href={`https://forms.hellolunajoy.com/screening/${record?.screeningSlugs.gds}`} target="_blank">
                      //         GDS
                      //       </a>{' '}
                      //       <br />
                      //     </>
                      //   )
                      // }
                    })}
                  </>
                ) : null} */}
                {record?.referralLink ? (
                  <>
                    {' '}
                    <a href={record?.referralLink} target="_blank" rel="noreferrer">
                      Referral Link{' '}
                    </a>{' '}
                    <br />
                  </>
                ) : (
                  <>
                    {' '}
                    Referral Link : NA
                    <br />
                  </>
                )}
              </>
            )}
          />

          <Column
            title="Tags"
            dataIndex="typesOfScreen"
            key="tags"
            render={(typesOfScreen, record) => {
              const clinicNameTag = () => {
                const clinicname = record?.name || '';
                const streetname = record?.streetName?.trim() || '';
                const state = record?.address || '';

                let specialty = '';
                if (record?.clinicGHLTags?.length) {
                  specialty = record.clinicGHLTags.find(ghlTag => ghlTag?.type === 'Specialty')?.value || '';
                }

                const input = [clinicname, specialty, streetname, state].filter(Boolean);
                return input.join('_').toLowerCase();
              }

              const tagStyle = { margin: '2px' };

              const renderScreeningTag = (tagFor) => {
                switch (tagFor) {
                  case 'Obgyn':
                    return (
                      <Tag color="blue" key={tagFor} style={tagStyle}>
                        {existingObgynTags[record.name] || `obgyn: ${record.tags}`}
                      </Tag>
                    );
                  case 'PHQ9-GAD7':
                    return (
                      <Tag color="blue" key={tagFor} style={tagStyle}>
                        {existingPhq9Gad7Tags[record.name] || `phq9-gad7: ${record.tags}`}
                      </Tag>
                    );
                  case 'Geriatric':
                    return (
                      <Tag color="blue" key={tagFor} style={tagStyle}>
                        {`gds: ${record.tags}`}
                      </Tag>
                    );
                  default:
                    return null;
                }
              };

              return (
                <>
                  {/* Clinic Tag */}
                  <Tag color="blue" key="clinic" style={tagStyle}>
                    {record.tags}
                  </Tag>

                  {/* Types of Screening Tags */}
                  {record?.typesOfScreen?.split(',').map((tagFor) => (
                    <div key={tagFor}>
                      {renderScreeningTag(tagFor)}
                    </div>
                  ))}

                  {/* Clinic GHL Tags */}
                  {record?.clinicGHLTags?.map((ghlTag) => (
                    <Tag color="blue" key={ghlTag.id} style={tagStyle}>
                      {ghlTag?.value}
                    </Tag>
                  ))}

                  {/* Clinic Name Tag */}
                  <Tag color="blue" key={record.id} style={tagStyle}>
                    {clinicNameTag()}
                  </Tag>
                </>
              );
            }
            }
          />

          <Column
            title="Action"
            dataIndex="id"
            key="id"
            width={130}
            minWidth={130}
            render={(id, record) => (
              <>
                <Tooltip title={'Delete'}>
                  <DeleteOutlined style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => handleDeleteClinic(id, record.name)} />
                </Tooltip>
                <Tooltip title={'Edit'}>
                  <Link to={`/edit-clinic/${id}`} style={{ color: 'black', textDecoration: 'none' }}>
                    <EditOutlined style={{ marginRight: '10px', cursor: 'pointer' }} />
                  </Link>
                </Tooltip>
                <Tooltip title={'Referral'}>
                  <Link to={`/referrals?carePartner=${id}`} style={{ color: 'black', textDecoration: 'none' }}>
                    <img src={Referral} alt="Referral Icon" style={{ width: '20px' }} />
                  </Link>
                </Tooltip>
                {/* <Tooltip title={"Screening"}><Link to={`/forms?clinic=${record.name}`} style={{ color: "black", textDecoration: "none" }}><img src={Submission} alt="Submission Icon" style={{ width: "20px" }} /></Link></Tooltip> */}
              </>
            )}
          />
        </Table>
      </Spin>

      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={true}
        centered={true}
        // closeIcon={<CloseOutlined onClick={() => setIsSubmitting(false)} />}
        style={{ minHeight: '300px', fontSize: '1rem' }}
      >
        {modalComponent}
      </Modal>
    </div>
  )
}

export default ClinicsDetails
