import React, { useState, useEffect, useRef } from "react";
import "../styles/Details.css";
import { notify } from "../utils/notify";
import { Table, Input, Tooltip, Spin, Tag, Modal, Button } from "antd";
import { generateScreeningPdf } from "../utils/pdfUtils";
import { getFormsDetailsWithFilter } from "../services/FormsSubmissionService";
import { getClinics } from "../services/ClinicsService";
import {
  formatDateAndTimeInlocal,
  formatDateForGeneralInfo,
} from "../utils/dateFormat";
import { saveAs } from "file-saver";
import XLSX from "sheetjs-style";
import useDebounce from "../hooks/useDebounce";
import { Link, useNavigate } from "react-router-dom";
const { Column } = Table;
const { Search } = Input;

function FormDetails() {
  document.title = "LunaJoy Dashboard";
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalComponent, setModalComponent] = useState();
  const [data, setData] = useState([]);
  const [clinicNames, setClinicNames] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [filterClinic, setFilterClinic] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const datePickerRef = useRef(null);
  const buttonRef = useRef(null);
  const clinicSet = new Set();

  const filterCardRef = useRef(null);
  const filterButtonRef = useRef(null);

  const navigate = useNavigate()

  const handleTableChange = (pagination, filters) => {
    setCurrentPage(pagination.current);
    setPageLimit(pagination.pageSize);
    setData([]);
    data.length = 0;
    loadData(pagination.current, pagination.pageSize);
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowDatePicker(false);
    }

    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target) &&
      filterCardRef.current &&
      !filterCardRef.current.contains(event.target)
    ) {
      setIsFilterVisible(false);
    }
  };

  const debouncedSearchValue = useDebounce(searchTerm);

  useEffect(() => {
    setCurrentPage(1);
    setPageLimit(10);
    setData([]);
    data.length = 0;
    loadData(1, 10);
  }, [filterClinic, debouncedSearchValue]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  async function loadClinicData() {
    try {
      const details = await getClinics();
      details.map((data) => {
        clinicSet.add(data.name);
      });
      setClinicNames([...clinicSet].sort());
    } catch (e) {
      console.log(e);
      Modal.confirm({
        title: "Error Message",
        content: "Oops! Error in Fetching data",
        okText: "Ok",
        onOk: async () => {
          console.log("Error in Fetching data");
        },
        cancelButtonProps: { style: { display: "none" } },
      });
    } finally {
      console.log(clinicNames);
    }
  }

  async function loadData(pageNo, pageSize) {
    const input = {};

    input["page"] = pageNo;
    input["limit"] = pageSize;

    if (filterClinic !== null && filterClinic !== undefined) {
      const clinics = [];
      clinics.push(filterClinic);
      input["clinics"] = clinics;
    }
    if (searchTerm !== "") {
      input["searchTerm"] = searchTerm;
    }
    try {
      setLoading(true);
      await loadClinicData();
      const details = await getFormsDetailsWithFilter(input);
      setTotalItems(
        details.data.FormSubmissionsCountByFilters.totalRecords === 0
          ? 1
          : details.data.FormSubmissionsCountByFilters.totalRecords
      );
      if (details.data.FormSubmissionsCountByFilters.data !== null) {
        setData(
          details.data.FormSubmissionsCountByFilters.data.map((data) => {
            // const uniqueContactMethods = Array.from(new Set(data?.assessmentHistory.map(a => a?.contactMethod)));
            const uniqueClinic = Array.from(new Set(data?.assessmentHistory.map(a => a?.clinic)));
            const modifiedAssessmentHistory = data?.assessmentHistory?.map(assessment => ({
              ...assessment,
              anxietyAnswers: assessment?.anxietyAnswers ? JSON.parse(assessment?.anxietyAnswers) : null,
              download: {
                epdsAnswers: assessment?.epdsAnswers ? JSON.parse(assessment?.epdsAnswers) : null,
                phq9Answers: assessment?.phq9Answers ? JSON.parse(assessment?.phq9Answers) : null,
                gad7Answers: assessment?.gad7Answers ? JSON.parse(assessment?.gad7Answers) : null,
                gdsAnswers: assessment?.gdsAnswers ? JSON.parse(assessment.gdsAnswers) : null,
              },
              type: assessment?.type === "obgyn" ? "EPDS" : assessment?.type?.toUpperCase(),
              date: assessment?.createdAt ? formatDateAndTimeInlocal(assessment?.createdAt) : null,
              dob: assessment?.dob ? formatDateFromTimestamp(assessment.dob) : null
            }));
            return {
              clinic: uniqueClinic.toString(),
              firstName: data.firstName,
              lastName: data.lastName,
              patientName: data.firstName + " " + data.lastName,
              name: [
                data.firstName,
                data.lastName,
                data.email.includes("notfound") ? "--" : data.email,
                data.phone === "00000000" ? "--" : data.phone,
              ],
              email: data.email.includes("notfound") ? "--" : data.email,
              phone: data.phone === "00000000" ? "--" : data.phone,
              dob: data.dob ? formatDateFromTimestamp(data.dob) : " ",
              assessmentCount: data?.assessmentCount,
              assessmentHistory: modifiedAssessmentHistory
            };
          })
        );
      }
    } catch (e) {
      console.log(e);
      Modal.confirm({
        title: "Error Message",
        content: "Oops! Error in Fetching data",
        okText: "Ok",
        onOk: async () => {
          console.log("Error in Fetching data");
        },
        cancelButtonProps: { style: { display: "none" } },
      });
    } finally {
      setLoading(false); // Reset loading once data fetching is complete
    }
  }

  function formatDateFromTimestamp(timestamp) {
    const date = new Date(parseInt(timestamp, 10));
    return formatDateForGeneralInfo(date);
  }


  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const generateExcel = (data) => {
    const columnsToInclude = [
      "id",
      "createdAt",
      "type",
      "epdsScore",
      "phq9Score",
      "gad7Score",
      "gdsScore",
    ];

    // Filter the data to include only the specified columns
    const filteredData = data.map((item) => {
      // Create a new object with only the specified columns
      const filteredItem = {};
      columnsToInclude.forEach((column) => {
        filteredItem[column] = item[column];
      });
      return filteredItem;
    });

    const filetype = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    return new Blob([excelBuffer], { type: filetype });
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };


  const handleDownloadMuster = async (data) => {
    const firstName = data[0]?.firstName
    const lastName = data[0]?.lastName
    try {
      let blobData, fileExtension;


      blobData = generateExcel(data);
      fileExtension = ".xlsx";


      //  save the pdf to user's local device
      saveAs(blobData, `${firstName}_${lastName}_assessment` + fileExtension);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      notify("Downloaded!", "Please check your downloads!");
    }
  };

  const viewDetails = async (data) => {
    const doc = generateScreeningPdf(data);
    const pdfBlob = doc.output('blob');
    setModalTitle("Assessment Details")
    setModalComponent(<><iframe
      src={URL.createObjectURL(pdfBlob)}
      title="PDF Viewer"
      style={{ width: "100%", height: "600px", border: "none" }}
    /></>)
    // setModalComponent(<></>)
  }

  const viewHistory = (data) => {
    setIsModalOpen(true)
    setModalTitle("")
    setModalComponent(
      <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>

          <hr style={{ width: '100%' }} ></hr>
          <h5><strong>Assessment History</strong></h5>
          <hr style={{ width: '100%' }} ></hr>
          <p><strong>Patient Details - </strong> {data[0]?.firstName} {data[0]?.lastName} |  {data[0]?.email}  |  {data[0]?.phone}</p>
        </div>
        <Table dataSource={data} getContainerWidth={1000} pagination={false} className="custom-apptHistory-table" tableLayout="fixed">
          <Column
            title="Assesment Date"
            dataIndex="date"
            key="date"

          />
          <Column title="Type" dataIndex="id" key="type" render={(id, record) => (
            <>
              {record.epdsScore !== null ? <div>EPDS </div> : null}
              {record.gdsScore !== null ? <div>GDS</div> : null}
              {record.phq9Score !== null && record.gad7Score !== null ? <div>PHQ9-GAD7</div> : null}
              {record.phq9Score !== null && record.gad7Score === null ? <div>PHQ-9</div> : null}
              {record.gad7Score !== null && record.phq9Score === null ? <div>GAD-7</div> : null}
            </>)} />
          <Column title="Score" dataIndex="id" key="id" render={(id, record) => (
            <>
              {record.epdsScore !== null ? <div>EPDS : {record.epdsScore}</div> : null}
              {record.gdsScore !== null ? <div>GDS : {record.gdsScore}</div> : null}
              {record.phq9Score !== null ? <div>PHQ-9 : {record.phq9Score}</div> : null}
              {record.gad7Score !== null ? <div>GAD-7 : {record.gad7Score}</div> : null}
            </>
          )} />
          <Column title="Actions" dataIndex="id" key="id" render={(id, record) => (
            <>
              <Tooltip title={"Download"}>
                <Link
                  onClick={() => handleDownloadMuster([record])}
                  style={{ cursor: "pointer" }}
                >Export a Excel</Link>
              </Tooltip>
              {` | `}
              <Tooltip title={"Download"}>
                <Link
                  onClick={() => viewDetails(record)}
                  style={{ cursor: "pointer" }}
                >View Details</Link>
              </Tooltip>
            </>
          )} />
        </Table>
      </>
    );
  }

  return (
    <div id="dashboard-container">
      {/* Add Clinic Filter */}
      <div className="row styleFilterRow">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: 5 }}>
            <span style={{ marginLeft: "18px", fontWeight: 500, marginRight: 10, marginTop: 13 }}>
              Total Patients
            </span>
            <Tag>
              <strong style={{ fontSize: 16 }}>{totalItems}</strong>
            </Tag>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <label htmlFor="search" className="labelStylingFilter" style={{ fontWeight: "bold" }}>
              Search Patient:
            </label>
            <Search
              id="search"
              placeholder="Search by First name, Last name, email or phone number"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onSearch={handleSearch}
              allowClear
              style={{ width: "25vw" }}
            />
          </div>
          <Button onClick={() => navigate('/reconcile-assessment')}>Reconcile Assessment</Button>
        </div>
      </div>

      <Spin spinning={loading}>
        <Table
          dataSource={data}
          pagination={{
            current: currentPage,
            total: totalItems,
            pageSize: pageLimit,
          }}
          bordered={true}
          onChange={handleTableChange}
          scroll={{ y: "65vh" }}
        >
          <Column
            title="Patient Name"
            dataIndex="name"
            key="name"
            render={(name) => (
              <>
                <strong>{name[0] + " " + name[1]}</strong>
                <div>{name[2]}</div>
                <div>{name[3]}</div>
              </>
            )}
          />
          <Column title="Referral Source" key="clinic" dataIndex="clinic" render={(clinic) => (<>
            {
              clinic?.length ? <>
                {clinic.split(",").map((referral) => {
                  return (<>{referral}<br /></>)
                })}

              </> : null
            }
          </>)} />

          {/* <Column title="Contact Method" dataIndex="contactMethod" key="contactMethod" render={(contactMethod) => (<>
           {
            clinic?.length ? <>
              {clinic.split(",").map((referral) => {
                  return (<>{referral}<br /></>)
              })}
          
      </> : null
           }
          </>)} />*/}



          <Column title="Total Assessment" dataIndex="assessmentCount" key="assessmentCount" />

          <Column
            title="Action"
            dataIndex="id"
            key="id"
            render={(id, record) => (
              <>
                <Tooltip title={"Download"}>
                  <Link
                    onClick={() => handleDownloadMuster(record.assessmentHistory)}
                    style={{ cursor: "pointer" }}
                  >Export a Excel</Link>
                </Tooltip>

                <>
                  {` |  `}
                  <Tooltip title={"View Assessment History"}>
                    <Link
                      onClick={() => viewHistory(record.assessmentHistory)}
                      style={{ cursor: "pointer" }}
                    >View  History</Link>
                  </Tooltip> </>
              </>
            )}
          />
        </Table>
      </Spin>


      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={true}
        centered={true}
        // closeIcon={<CloseOutlined onClick={() => setIsSubmitting(false)} />}
        style={{ minHeight: "300px", minWidth: "800px", fontSize: "1rem" }}
      >
        {modalComponent}
      </Modal>
    </div>
  );
}

export default FormDetails;
