export const oldObGynClinic = [
    'associates-of-marietta',
    'arbor',
    'clifton-ob-gyn',
    'dekalb',
    'gentle-hands',
    'magnolia-birth-house',
    'northpoint'
]

export const oldObgynClinicSlugMap = {
    'associates-of-marietta' : 'associates-of-marietta',
    'arbor' : 'arbor',
    'clifton-ob-gyn' : 'clifton-obgyn',
    'dekalb' :'dekalb',
    'gentle-hands' : 'gentle-hands',
    'magnolia-birth-house' :'magnolia-birth-house',
    'northpoint' :'northpoint'
}