import React, { useState, useEffect, useRef } from "react";
import "../styles/Details.css";
import Preview from "../assets/view.png"
import { VerticalAlignBottomOutlined, FilterFilled } from "@ant-design/icons";
import { Button, Table, Space, Input, Tooltip, Select, Spin, Tag, Modal, Checkbox, Image } from "antd";
import { formatDate, formatDateAndTime, formateDateForFax } from "../utils/dateFormat";
import MonthlyFaxServices from "../services/MonthlyFaxService";
import { useParams, useNavigate, useLocation } from "react-router-dom";
// Import the main component

const { Column } = Table;

function MonthlyFaxReview() {
  document.title = "Fax Review"
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalComponent, setModalComponent] = useState();
  const [file, setFile] = useState();
  const [data, setData] = useState([]);
  const [clinicNames, setClinicNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filtered, setFiltered] = useState([])
  const [filters, setFilters] = useState({});
  const [showDatePicker, setShowDatePicker] = useState(false)

  const datePickerRef = useRef(null);
  const buttonRef = useRef(null);
  const clinicSet = new Set();

  const [numPage, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [retryClicked, setRetryClicked] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [isToggleOn, setToggleOn] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    console.log('pdfBlob', pdfBlob);
  }, [pdfBlob])

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const navigationItems = [
    isToggleOn && {
      label: "Fax logs",
      key: "/audit-logs",
    },
  ];
  const handleTableChange = (pagination, filters) => {
    setFilters(filters);
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowDatePicker(false);
    }
  };


  // const handleToggleClick = () => {
  //   setToggleOn(!isToggleOn);
  //   navigate('/audit-logs');

  // };

  useEffect(() => {
    loadData()

  }, [refresh]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  async function loadData() {
    try {
      setLoading(true);
      const logs = await MonthlyFaxServices.getMonhtlyFaxReview();
      setData(logs);
    } catch (e) {
      console.log(e)
      Modal.confirm({
        title: 'Error Message',
        content: 'Oops! Error in Fetching data',
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } },
        onOk: async () => {
          console.log("Error in Fetching data");
        },
      });
    } finally {
      setLoading(false); // Reset loading once data fetching is complete
    }
  }

  const handlePreviewDocument = async (record) => {
    try {
      setIsLoading(true);
      const fileBlob = await MonthlyFaxServices.getDocument(record);
      const windowUrl = window.URL.createObjectURL(fileBlob);
      console.log('windowUrl', windowUrl);
      setPdfBlob(windowUrl);
      setIsModalOpen(true);
      setModalTitle('Document Preview');
    } catch (error) {
      console.error('Error Preview the document:', error);
    } finally {
      setIsLoading(false);
    }
  };



  const handleGeneratedRetry = async (record) => {
    if (!retryClicked) {
      // Send a message when the button is clicked for the first time
      alert('Retry done!');
      setRetryClicked(true);
      try {

        await MonthlyFaxServices.handleFaxGeneratedRetry(record.id);

        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    }
  };




  const filteredData = data.filter((item) => {
    return (!filtered.clinic || (item.clinic === filtered.clinic));
  });


  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    window.location.reload()
    setIsModalOpen(false);
  };
  const handleColumnClick = () => {
    setChecked(!isChecked);

  }

  const handlePdfModalOk = () => {
    setPdfBlob('');
    setIsPdfModalOpen(false);
  };

  const handlePdfModalCancel = () => {
    setIsPdfModalOpen(false);
  };

  const closeModal = () => {
    setPdfBlob('');
    setIsModalOpen(false);
  };

  const handleReview = async (record) => {
    if (!isChecked) {
      alert('Review done!');
      setChecked(true);
      try {

        await MonthlyFaxServices.handelIsReview(record.id);


      } catch (error) {
        console.error(error);
      }
      window.location.reload();
    }
  };

  return (
    <div id="dashboard-container">
      <div className='row styleFilterRow'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          
            <div style={{ position: "relative" }}>
              {isToggleOn && navigationItems.map((item, index) => (
                item && (
                  <div key={index}>
                    <span>{item.label}</span>
                  </div>
                )
              ))}
            </div>
            <div style={{ marginTop: 5 }}>
              <span style={{ marginLeft: "18px",fontWeight: 500, marginRight: 10, marginTop: 13 }}>Total Logs</span>
              <Tag>
                <strong style={{ fontSize: 16 }}>{filteredData.length === 0 ? 0 : filteredData.length}</strong>
              </Tag>
              <Button style={{ fontsize: 16, padding: 5 }}  onClick={() => navigate("/faxes")}>Fax Dashboard</Button>
            </div>
            
          </div>

  
        </div>



        <Spin spinning={loading}>
          <Table
            dataSource={filteredData}
            onChange={handleTableChange}
            bordered={true}

          >
            {!filtered.clinic && (
              <Column
                title="Clinic"
                dataIndex="clinic"
                key="clinic"


              />
            )}

            <Column

              title="Fax Type"
              dataIndex="type"
              key="channel"
            />

            <Column

              title={"Review Status"}
              dataIndex="isReviewed"
              key="isReviewed"
              onClick={handleColumnClick}
              render={(id, record) => (
                <>
                  <Tooltip title={record.isReviewed !== false ? "Reviewed" : "Mark as review"}>

                    <Button
                      disabled={record.isReviewed !== false}
                      onClick={() => {
                        handleReview(record);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {record.isReviewed !== false ? "Reviewed" : "Mark as review"}
                    </Button>
                  </Tooltip>
                </>
              )}
            />



            <Column
              title="Generated Date"
              dataIndex="createdAt"
              key="createdAt"

              width={130}
              render={(date) => (
                <>
                  <div>{date ? formatDate(date) : " "}</div>
                </>
              )}

            />
            <Column
              title="Preview"
              dataIndex="id"
              key="id"

              render={(id, record) => {
                if (record.document) {
                  return (
                    <>
                      <Tooltip title={"Preview"}><Image src={Preview} width={24} preview={false} onClick={() => handlePreviewDocument(record)}
                        style={{ cursor: "pointer" }} /></Tooltip>

                    </>
                  )
                } else {
                  return (
                    <>
                      <Tooltip title={"Retry"} key={id}>
                        <Button
                          onClick={() => {
                            handleGeneratedRetry(record);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Retry
                        </Button>
                      </Tooltip>
                    </>
                  )

                }
              }}
            />
          </Table>

        </Spin>

        <Modal
          title={modalTitle}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          maskClosable={true}
          centered={true}
          width={'100%'}
          height={'100%'}
          style={{ width: '100%', height: '100%' }}
          bodyStyle={{ height: '100%', padding: 0, overflow: 'auto' }}
        >
          {pdfBlob && <object style={{ width: '100%', height: '95vh' }} data={pdfBlob}></object>}
        </Modal>




      </div >
      );
}

      export default MonthlyFaxReview;
