import React, { useState, useEffect, useRef } from "react";
import "../styles/Details.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { DateRangePicker } from 'react-date-range';
import CalendarGrey from '../assets/Calendar Dark.svg'
import CalendarBlue from '../assets/Calendar Blue.svg'
import FaxReview from "../assets/fax.png"
import { VerticalAlignBottomOutlined, InfoCircleOutlined, FilterFilled, FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Table, Space, Input, Tooltip, Select, Spin, Tag, Modal, Card } from "antd";
import { handleDownloadPdf } from "../utils/pdfUtils";
import moment from "moment";
import { formatDate, formatDateAndTime, formatDateAndTimeInlocal, formateDateForAuditlog, dateFormatGeneric, formateDateForFax, formatDateInlocal } from "../utils/dateFormat";
import AuditLogsServices from "../services/AuditLogsService";
import FilterComponent from "../components/FilterComponent";
import MonthlyFaxServices from "../services/MonthlyFaxService";
const { Column } = Table;
const { Option } = Select;

// import { useHistory } from 'react-router-dom';

function AuditLogs() {
    document.title = "LunaJoy Dashboard"
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalComponent, setModalComponent] = useState();
    const [data, setData] = useState([]);
    const [clinicNames, setClinicNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [filtered, setFiltered] = useState([])
    const [filters, setFilters] = useState({});
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [dateRangeFilter, setDateRangeFilter] = useState([]);
    const datePickerRef = useRef(null);
    const buttonRef = useRef(null);
    const clinicSet = new Set();
    const [retryClicked, setRetryClicked] = useState(false);
    const [filterClinic, setFilterClinic] = useState(null);
    const [faxType, setFaxType] = useState(null);
    const [faxStatus, setFaxStatus] = useState(null);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [totalItems, setTotalItems] = useState(20)
    const navigate = useNavigate();
    const [isReviewedFalseCount, setIsReviewedFalseCount] = useState(0);

    const filterCardRef = useRef(null);
    const filterButtonRef = useRef(null);



    const handleTableChange = (pagination, filters) => {
        setFilters(filters);
        setLoading(true)
        setCurrentPage(pagination.current)
        setPageLimit(pagination.pageSize)
        setLoading(false)
    };

    const handleClickOutside = (event) => {
        if (
          datePickerRef.current &&
          !datePickerRef.current.contains(event.target) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target)
        ) {
          setShowDatePicker(false);
          
        }
    
        if (
          filterButtonRef.current &&
          !filterButtonRef.current.contains(event.target) &&
          filterCardRef.current &&
          !filterCardRef.current.contains(event.target)
        ) {
          setIsFilterVisible(false);
          
        }
      };

    const getSixDaysAgoFromCurrentDate = () => {
        const today = new Date();
        const sixDaysAgo = new Date(today);
        sixDaysAgo.setDate(today.getDate() - 6);
        return sixDaysAgo;
    };

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const handleDateChange = (ranges) => {
        setDateRange([ranges.selection]);

    };


    const handleToggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };
    const handleClose = () => {
        setIsFilterVisible(false);
    };
    // dateFormatGeneric
    const startDate = formateDateForAuditlog(dateRange[0].startDate)
    const endDate = formateDateForAuditlog(dateRange[0].endDate)
    const momentFormatStartDate = moment(startDate).format("D/M/YYYY");
    const momentFormatEndDate = moment(endDate).format("D/M/YYYY");
    



    useEffect(() => {
        loadData();
    }, [refresh, filterClinic, faxStatus, faxType, startDate, endDate,]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    async function loadData() {
        try {
            setLoading(true);
            await loadClinicData();
            const details = await AuditLogsServices.getAuditLogsClinics(filterClinic, faxType, faxStatus, startDate, endDate);
            setTotalItems(details.totalItems === 0 ? 1 : details.totalItems)
            setData(
                details.map((record) => {
                    return {
                        id: record.id,
                        clinic: record.clinic,
                        channel: record.channel,
                        createdAt: record.createdAt,
                        document: record.document,
                        type: record.type,
                        status: record.status,
                        retryCount: record.retryCount,
                        payload: record.payload,
                        updatedAt: record.updatedAt

                    };
                })
            );
        } catch (e) {
            console.log(e)
            Modal.confirm({
                title: 'Error Message',
                content: 'Oops! Error in Fetching data',
                okText: 'Ok',
                cancelButtonProps: { style: { display: 'none' } },
                onOk: async () => {
                    console.log("Error in Fetching data");
                },
            });
        } finally {
            setLoading(false); // Reset loading once data fetching is complete
        }
    }



    function formatDateFromTimestamp(timestamp) {
        const date = new Date(parseInt(timestamp, 10));

        return formatDate(date)
    }

    const handleDownloadDocument = async (record) => {
        console.log("record ", record);
        await AuditLogsServices.getDocument(record);
    }


    const handleRetry = async (record) => {
        if (!retryClicked) {
            alert('Retry done!');
            setRetryClicked(true);
            try {
                await AuditLogsServices.handleRetry(record.id);

                window.location.reload();
            } catch (error) {
                console.error(error);
            }
        }
    };
    const [status, setStatus] = useState('Pending');
    // const [failedStatus, setFailedStatus] = useState('Pending');

    const failedStatus = 'Failed'
    const successStatus = 'Success'

    const filteredData = data.filter((item) => {
        return (!filtered.clinic || (item.clinic === filtered.clinic));
    });
    const filteredDataPending = filteredData.filter((item) => {
        return item.status === status;
    });
    const filteredDataFailed = filteredData.filter((item) => {
        return item.status === failedStatus;
    });
    const filteredDataSuccess = filteredData.filter((item) => {
        return item.status === successStatus;
    });


    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleFilter = () => {
        setIsFilterVisible(true);
    };
    // const history = useHistory();

    const [pendingTimestamp, setPendingTimestamp] = useState(new Date());

    useEffect(() => {
        setTimeout(() => {
            setStatus('Pending');
            setPendingTimestamp(new Date());
        }, 3000);
    }, []);

    const calculateDateDifference = (pendingTimestamp) => {
        const date1Millis = pendingTimestamp.getTime();
        const date2Millis = new Date().getTime();
    
        const differenceMillis = Math.abs(date2Millis - date1Millis);
    
        const differenceMinutes = differenceMillis / (1000 * 60);
    
        
        const roundedDifferenceMinutes = Math.round(differenceMinutes);
    
        return roundedDifferenceMinutes;
    };

    const getStatusText = (status, timestamp, updatedAt) => {
        let pendingTimestamp = new Date(timestamp.createdAt)
        const minuteDifference =calculateDateDifference(pendingTimestamp)
        if (status === 'Success') {
            return <p>Sent <br />{formatDateAndTimeInlocal(timestamp.updatedAt)}</p>;
        } else if (status === 'Pending' && pendingTimestamp) {
            return (
                <p>
                    Pending
                    <br />
                    {minuteDifference > 1
                        ? `since ${minuteDifference} minutes`
                        : `since ${minuteDifference} minute`}
                </p>
            );
        } else if (status === 'Failed') {
            return <p>Failed</p>;
        } else {
            return status;
        }
    };
    
    const getStatusTextColor = (status) => {
        if (status === 'Success') {
            return 'green';
        } else if (status === 'Pending') {
            return 'orange';
        } else if (status === 'Failed') {
            return 'red';
        } else {
            return 'black';
        }
    };
    async function loadClinicData() {
        try {
            const details = await AuditLogsServices.getAuditLogsClinics(filterClinic, faxType, faxStatus, startDate, endDate);
            setTotalItems(details.totalItems === 0 ? 1 : details.totalItems)
            details.map((data) => {
                clinicSet.add(data.clinic);
            });
            setClinicNames([...clinicSet].sort());
        } catch (e) {
            console.log(e);
            Modal.confirm({
                title: "Error Message",
                content: "Oops! Error in Fetching data",
                okText: "Ok",
                onOk: async () => {
                    console.log("Error in Fetching data");
                },
                cancelButtonProps: { style: { display: 'none' } }
            });
        } finally {
            console.log(clinicNames);
        }
    };


    async function loadMonthlyFaxData() {
        try {
            setLoading(true);
            const logs = await MonthlyFaxServices.getMonhtlyFaxReview();
            const count = logs.reduce((acc, entry) => acc + (entry.isReviewed === false ? 1 : 0), 0);
            setIsReviewedFalseCount(count);
        } catch (e) {
            console.log(e)
            Modal.confirm({
                title: 'Error Message',
                content: 'Oops! Error in Fetching data',
                okText: 'Ok',
                cancelButtonProps: { style: { display: 'none' } },
                onOk: async () => {
                    console.log("Error in Fetching data");
                },
            });
        } finally {
            setLoading(false);
        }
    }
    const handleButtonClick = () => {
        setShowPopup(true);

    };

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <div id="dashboard-container">
            <div className='row styleFilterRow'>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <div style={{ position: 'relative' }}>
                        <span style={{ marginLeft: "18px", fontWeight: 500, marginRight: 10 }}>Total Fax</span>
                        <Tag>
                            <strong style={{ fontSize: 16 }}>{filteredData.length === 0 ? 0 : filteredData.length}</strong>
                        </Tag>
                        <Button style={{ fontsize: 16, padding: 5 }} onClick={handleButtonClick}>Fax Report</Button>
                        {showPopup && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    zIndex: 100,
                                    transition: "transform 0.3s ease-out",
                                    boxShadow: "0 0 10px rgba(0, 0, 0, 1.5)",
                                }}
                            >
                                <Card
                                    title="Fax Report Summary"
                                    bordered={true}
                                    extra={
                                        <Button type="Close" onClick={closePopup}>
                                            <CloseCircleOutlined />
                                        </Button>

                                    }
                                    style={{ width: '500px' }}
                                >
                                    <table style={{ width: '100%', marginTop: -15 }}>
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th border style={{ textAlign: 'center' }}>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td border style={{ border: '1px solid #e9ecef', }}>Total Number of Fax Generated</td>

                                                <td border style={{ border: '1px solid #e9ecef', textAlign: 'center' }}>{filteredData.length === 0 ? 0 : filteredData.length}</td>
                                            </tr>
                                            <tr>
                                                <td border style={{ border: '1px solid #e9ecef', }}>Total Number of Fax Sent </td>
                                                <td border style={{ border: '1px solid #e9ecef', textAlign: 'center' }}>{filteredDataSuccess.length === 0 ? 0 : filteredDataSuccess.length}</td>
                                            </tr>
                                            <tr>
                                                <td border style={{ border: '1px solid #e9ecef', }}>Successful Delivery</td>
                                                <td border style={{ border: '1px solid #e9ecef', textAlign: 'center' }}>{filteredDataSuccess.length === 0 ? 0 : filteredDataSuccess.length}</td>
                                            </tr>
                                            <tr>
                                                <td border style={{ border: '1px solid #e9ecef', }}>Unsuccessful Delivery</td>
                                                <td border style={{ border: '1px solid #e9ecef', textAlign: 'center' }}>{filteredDataFailed.length === 0 ? 0 : filteredDataFailed.length}</td>
                                            </tr>
                                            <tr>
                                                <td border style={{ border: '1px solid #e9ecef', }}>Delivery Pending</td>
                                                <td border style={{ border: '1px solid #e9ecef', textAlign: 'center' }}>{filteredDataPending.length === 0 ? 0 : filteredDataPending.length}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Card>
                            </div>
                        )}

                        <Button style={{ fontsize: 16, padding: 5, marginLeft: 10 }} onClick={() => navigate("/MonhtlyFaxReview")}>
                            <span>Review Monthly Fax </span>
                            {isReviewedFalseCount >= 1 && (
                                <span style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '10px' }}>
                                    <Tooltip
                                        title={`Number of generated monthly fax yet to review ${isReviewedFalseCount}`}
                                        placement="right"
                                    >
                                        <InfoCircleOutlined style={{ position: "absolute", top: "0px", fontSize: "13px", color: 'red' }} />
                                    </Tooltip>
                                </span>
                            )}
                        </Button>

                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <span className="dateStyling" style={{ marginRight: "10px" }}>
                            Filter
                        </span>
                        <div style={{ position: "relative" }}>
                            <Tooltip title="Filter" >
                                <FilterOutlined
                                    style={{ marginRight: "10px", fontSize: "18px", marginTop: "5px", cursor: "pointer" }}
                                    ref={filterButtonRef}
                                    onClick={handleFilter}
                                />
                            </Tooltip>

                            {isFilterVisible && (
                                <div
                                    style={{ position: "absolute", top: 0, right: 0, zIndex: 100 }} ref={filterCardRef}
                                >
                                    <Card
                                        title="Filter Options"
                                        bordered={true}
                                        extra={
                                            <Button type="Close" onClick={handleClose}>
                                                <CloseCircleOutlined />
                                            </Button>
                                        }
                                    >
                                        <FilterComponent
                                            clinicNames={clinicNames}
                                            filteredClinic={filterClinic}
                                            setFilteredClinic={setFilterClinic}
                                            faxType={faxType}
                                            setFaxType={setFaxType}
                                            faxStatus={faxStatus}
                                            setFaxStatus={setFaxStatus}
                                            from={"Fax"}
                                        />
                                    </Card>
                                </div>
                            )}
                        </div>


                        <span className='dateStyling'>{momentFormatStartDate + ' to ' + momentFormatEndDate}</span>
                        <span ref={buttonRef} onClick={handleToggleDatePicker} className='marginAdjustCalendarToggleButton'>{showDatePicker ? <img src={CalendarBlue} alt="img" /> : <img src={CalendarGrey} alt="img" />}</span>
                        {showDatePicker && (
                            <div className="date-picker-container" ref={datePickerRef} style={{ position: 'absolute', zIndex: '9999', top: '10.5rem', display: 'flex', justifyContent: 'end', right: '30px' }}>
                                <DateRangePicker
                                    ranges={dateRange}
                                    onChange={handleDateChange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Spin spinning={loading}>
                <Table
                    dataSource={filteredData}
                    pagination={{
                        current: currentPage,
                        total: totalItems,
                        pageSize: pageLimit,

                    }}
                    onChange={handleTableChange}
                    bordered={true}
                >
                    {!filtered.clinic && (
                        <Column
                            title="Clinic"
                            dataIndex="clinic"
                            key="clinic"


                        />
                    )}
                    {/*<Column
                        title="Recipient"
                        dataIndex="clinic"
                        key="payload"
                        render={(clinic, record) => (
                            record.type && (record.type.includes("Responsiveness") || record.type.includes("First Note")) ?
                                record.payload.templateData ? (
                                    <p>{record.payload.templateData.clinician} </p>
                                ) : (
                                    "Not Applicable"
                                )
                                :
                                "Not Applicable"
                        )}
                    />*/}

                    <Column
                        title="Patient"
                        dataIndex="clinic"
                        key="payload"
                        render={(clinic, record) => (
                            record.type && (record.type.includes("Responsiveness") || record.type.includes("First Note")) ?
                                record.payload.templateData ? (
                                    <p>{record.payload.templateData.firstName} {record.payload.templateData.lastName}<br /> {record?.payload?.templateData?.patientPhone || ''}</p>
                                ) : (
                                    "Not Applicable"
                                )
                                :
                                "Not Applicable"
                        )}


                    />

                    <Column
                        title="Type"
                        dataIndex="type"
                        key="type"
                    />
                    <Column
                        title="Status"
                        dataIndex="status"
                        key="updatedAt"
                        render={(status, date, record) => (
                            <>
                                <Tag
                                    style={{
                                        width: '150px',
                                        height: '40px', textAlign: 'left'
                                    }}
                                    color={getStatusTextColor(status)}
                                    key={status}
                                >
                                    {getStatusText(status, date, record)}

                                </Tag>
                            </>
                        )}
                    />

                    <Column
                        title="Retry Count"
                        dataIndex="retryCount"
                        key="retryCount"
                    />

                    <Column
                        title="Generated"
                        dataIndex="createdAt"
                        key="Generated"
                        width={130}
                        render={(date) => (
                            <>
                                <div>{date ? formatDateAndTimeInlocal(date) : " "}</div>
                            </>
                        )}

                    />

                    <Column
                        title="Download"
                        dataIndex="id"
                        key="id"
                        render={(id, record) => {
                            if (record.document) {
                                return (
                                    <>
                                        <Tooltip title={"Download"}><VerticalAlignBottomOutlined onClick={() => handleDownloadDocument(record)}
                                            style={{ cursor: "pointer" }} /></Tooltip>

                                    </>
                                )
                            }
                        }}
                    />
                    <Column
                        title="Action"
                        dataIndex="id"
                        key="id"
                        render={(id, record) => (
                            <>
                                <Tooltip title={"Retry"} key={id}>
                                    <Button
                                        disabled={record.status !== 'Failed'}
                                        onClick={() => {
                                            handleRetry(record);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Retry
                                    </Button>
                                </Tooltip>
                            </>
                        )}
                    />

                </Table>

            </Spin>
            <Modal
                title={modalTitle}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={true}
                centered={true}
                style={{ minHeight: "300px", fontSize: "1rem" }}
            >
                {modalComponent}
            </Modal>
        </div >
    );
}


export default AuditLogs;
